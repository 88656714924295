@use 'variables';
@use '@angular/material' as mat;
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import '@angular/material';
@import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
@import 'mapbox-gl/dist/mapbox-gl.css';
@import 'material-icons/iconfont/material-icons';
@import 'ngx-toastr/toastr';
@import 'source-sans-pro/source-sans-pro.css';
@import 'preload-view';
@import 'theme';
@import 'vendors';

@include mat.core();
$custom-typography: mat.m2-define-typography-config(
    $font-family: (
        'Source Sans Pro',
        sans-serif,
    ),
);
$custom-theme: mat.m2-define-light-theme(
    (
        typography: $custom-typography,
    )
);
@include mat.all-component-themes($custom-theme);

html,
body {
    margin: 0;
    position: fixed;
    overflow: hidden;
}

* {
    font-family: 'Source Sans Pro', sans-serif;
    box-sizing: border-box;
}

a {
    color: var(--color-brand-secondary);

    &:hover,
    &:visited {
        color: var(--color-brand-primary);
    }
}

.form-error {
    color: variables.$color-red;
    margin-top: -24px;
    margin-bottom: 6px;
    height: 18px;
}
